import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/tutorials.tsx";
export const pageTitle = "Implementing READ operation";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const TutorialSteps = makeShortcode("TutorialSteps");
const Tabs = makeShortcode("Tabs");
const TabPane = makeShortcode("TabPane");
const layoutProps = {
  pageTitle,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "implementing-read-operation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#implementing-read-operation",
        "aria-label": "implementing read operation permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Implementing READ operation`}</h1>
    <h6 {...{
      "className": "inlinePageToc",
      "role": "navigation"
    }}>{`Table of contents`}</h6>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#what-you-need"
        }}>{`What you need`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#1-implement-server-side"
        }}>{`1. Implement server-side`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#2-implement-client-side"
        }}>{`2. Implement client-side`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#3-test-retrieving-a-single-post"
        }}>{`3. Test retrieving a single post`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#4-test-an-error-case"
        }}>{`4. Test an error case`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#5-test-retrieving-multiple-posts"
        }}>{`5. Test retrieving multiple posts`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#whats-next"
        }}>{`What's next`}</a></li>
    </ul>
    <p>{`In the earlier step, we created blog posts.
In this step, we'll implement a read operation and make a call to read blog posts.
We'll write two service methods, one for reading a single post and another for multiple posts.`}</p>
    <TutorialSteps current={4} mdxType="TutorialSteps" />
    <h2 {...{
      "id": "what-you-need",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#what-you-need",
        "aria-label": "what you need permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What you need`}</h2>
    <p>{`You need to have the following files obtained from previous steps.
You can always `}<a parentName="p" {...{
        "href": "https://github.com/line/armeria-examples/tree/main/tutorials/thrift"
      }}>{`download`}</a>{` the full version, instead of creating one yourself.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tutorials/thrift/blog/define-service#3-compile-the-thrift-file"
        }}>{`Generated Java code`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`BlogServiceImpl.java`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`Main.java`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`BlogClient.java`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`BlogServiceTest.java`}</inlineCode></li>
    </ul>
    <h2 {...{
      "id": "1-implement-server-side",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#1-implement-server-side",
        "aria-label": "1 implement server side permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1. Implement server-side`}</h2>
    <p>{`Let's write two methods for retrieving blog posts; one for a single post and another for multiple posts.`}</p>
    <Tabs mdxType="Tabs">
      <TabPane tab="Single post" key="1" mdxType="TabPane">
        <p>{`In the `}<inlineCode parentName="p">{`BlogServiceImpl`}</inlineCode>{` class, implement the `}<inlineCode parentName="p">{`getBlogPost()`}</inlineCode>{` method to retrieve a single post.
Let's throw an exception in case there is no blog post for the given ID.`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-java",
            "metastring": "filename=BlogServiceImpl.java",
            "filename": "BlogServiceImpl.java"
          }}>{`import example.armeria.blog.thrift.BlogNotFoundException;
...
@Override
public void getBlogPost(GetBlogPostRequest request, AsyncMethodCallback<BlogPost> resultHandler)
        throws TException {
  final BlogPost blogPost = blogPosts.get(request.getId());
  if (blogPost == null) {
    // throwing an exception will also have the same effect
    // throw new BlogNotFoundException("The blog post does not exist. ID: " + request.getId());
    resultHandler.onError(
            new BlogNotFoundException("The blog post does not exist. ID: " + request.getId()));
  } else {
    resultHandler.onComplete(blogPost);
  }
}
`}</code></pre>
      </TabPane>
      <TabPane tab="Multiple posts" key="2" mdxType="TabPane">
        <ol>
          <li parentName="ol">{`Implement the `}<inlineCode parentName="li">{`listBlogPosts()`}</inlineCode>{` method in the `}<inlineCode parentName="li">{`BlogServiceImpl`}</inlineCode>{` class to retrieve multiple posts.`}
            <pre parentName="li"><code parentName="pre" {...{
                "className": "language-java",
                "metastring": "filename=BlogServiceImpl.java",
                "filename": "BlogServiceImpl.java"
              }}>{`import java.util.List;
import java.util.stream.Collectors;
...
@Override
public void listBlogPosts(ListBlogPostsRequest request,
                          AsyncMethodCallback<ListBlogPostsResponse> resultHandler) throws TException {
  final List<BlogPost> blogPosts = this.blogPosts.values().stream().collect(Collectors.toList());
  resultHandler.onComplete(new ListBlogPostsResponse().setBlogs(blogPosts));
}
`}</code></pre>
          </li>
          <li parentName="ol">{`Add an if-else statement by adding line 9-16, to sort the blog posts based on the value of the `}<inlineCode parentName="li">{`descending`}</inlineCode>{` parameter.`}
            <pre parentName="li"><code parentName="pre" {...{
                "className": "language-java",
                "metastring": "filename=BlogServiceImpl.java showlineno=true",
                "filename": "BlogServiceImpl.java",
                "showlineno": "true"
              }}>{`import java.util.Collections;
import java.util.Comparator;
import java.util.Map.Entry;
...
@Override
public void listBlogPosts(ListBlogPostsRequest request,
                          AsyncMethodCallback<ListBlogPostsResponse> resultHandler) throws TException {
  final List<BlogPost> blogPosts;
  if (request.isDescending()) {
    blogPosts = this.blogPosts.entrySet()
                              .stream()
                              .sorted(Collections.reverseOrder(Comparator.comparingInt(Entry::getKey)))
                              .map(Entry::getValue).collect(Collectors.toList());
  } else {
    blogPosts = this.blogPosts.values().stream().collect(Collectors.toList());
  }
  resultHandler.onComplete(new ListBlogPostsResponse().setBlogs(blogPosts));
}
`}</code></pre>
          </li>
        </ol>
      </TabPane>
    </Tabs>
    <h2 {...{
      "id": "2-implement-client-side",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#2-implement-client-side",
        "aria-label": "2 implement client side permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`2. Implement client-side`}</h2>
    <p>{`This time, we'll implement the client-side for reading blog posts.
Let's implement client methods for each corresponding server method.`}</p>
    <Tabs mdxType="Tabs">
      <TabPane tab="Single post" key="1" mdxType="TabPane">
        <p>{`In the `}<inlineCode parentName="p">{`BlogClient`}</inlineCode>{` class, add a method to retrieve a single post.`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-java",
            "metastring": "filename=BlogClient.java",
            "filename": "BlogClient.java"
          }}>{`import example.armeria.blog.thrift.GetBlogPostRequest;
...
BlogPost getBlogPost(int id) throws TException {
  final GetBlogPostRequest request =
              new GetBlogPostRequest().setId(id);
  return blogService.getBlogPost(request);
}
`}</code></pre>
      </TabPane>
      <TabPane tab="Multiple posts" key="2" mdxType="TabPane">
        <p>{`In the `}<inlineCode parentName="p">{`BlogClient`}</inlineCode>{` class, add a method to retrieve a list of posts.`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-java",
            "metastring": "filename=BlogClient.java",
            "filename": "BlogClient.java"
          }}>{`import example.armeria.blog.thrift.ListBlogPostsRequest;
import example.armeria.blog.thrift.ListBlogPostsResponse;
...
List<BlogPost> listBlogPosts(boolean descending) throws TException {
  return blogService.listBlogPosts(new ListBlogPostsRequest().setDescending(descending))
                    .getBlogs();
}
`}</code></pre>
      </TabPane>
    </Tabs>
    <h2 {...{
      "id": "3-test-retrieving-a-single-post",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#3-test-retrieving-a-single-post",
        "aria-label": "3 test retrieving a single post permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`3. Test retrieving a single post`}</h2>
    <p>{`Let's test if we can retrieve a blog post we created.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`In the `}<inlineCode parentName="p">{`BlogServiceTest`}</inlineCode>{` class, add a test method to retrieve the first blog post with ID `}<inlineCode parentName="p">{`0`}</inlineCode>{`.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java",
            "metastring": "filename=BlogServiceTest.java",
            "filename": "BlogServiceTest.java"
          }}>{`@Test
void getBlogPost() throws TException {
  final BlogClient client = new BlogClient(server.httpUri(), "/thrift");
  final BlogPost blogPost = client.getBlogPost(0);

  assertThat(blogPost.getTitle()).isEqualTo("My first blog");
  assertThat(blogPost.getContent()).isEqualTo("Hello Armeria!");
}
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Add annotations to configure the order our test methods will be executed.
The annotations guarantee that the first blog post will be created in the `}<inlineCode parentName="p">{`createBlogPost()`}</inlineCode>{` method before we try to retrieve it in the `}<inlineCode parentName="p">{`getBlogPost()`}</inlineCode>{` method.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java",
            "metastring": "filename=BlogServiceTest.java",
            "filename": "BlogServiceTest.java"
          }}>{`import org.junit.jupiter.api.MethodOrderer.OrderAnnotation;
import org.junit.jupiter.api.Order;
import org.junit.jupiter.api.TestMethodOrder;

@TestMethodOrder(OrderAnnotation.class) // Add this
class BlogServiceTest {
  ...

  @Test
  @Order(1) // Add this
  void createBlogPost() throws TException {
    ...
  }

  @Test
  @Order(2) // Add this
  void getBlogPost() throws TException {
    ...
  }
}
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Run all the test cases on your IDE or using Gradle.`}</p>
        <p parentName="li">{`Your client retrieved a blog post from the server successfully if the test is passed.`}</p>
      </li>
    </ol>
    <h2 {...{
      "id": "4-test-an-error-case",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#4-test-an-error-case",
        "aria-label": "4 test an error case permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`4. Test an error case`}</h2>
    <p>{`Let's try retrieving a blog post that does not exist.
Add a test method to retrieve a blog post with an invalid ID, asserting an exception is thrown.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java",
        "metastring": "filename=BlogServiceTest.java",
        "filename": "BlogServiceTest.java"
      }}>{`import static org.assertj.core.api.Assertions.catchThrowable;
import example.armeria.blog.thrift.BlogNotFoundException;
...
@Test
@Order(3)
void getInvalidBlogPost() {
  final BlogClient client = new BlogClient(server.httpUri(), "/thrift");
  final Throwable exception = catchThrowable(() -> {
    client.getBlogPost(Integer.MAX_VALUE);
  });
  assertThat(exception)
    .isInstanceOf(BlogNotFoundException.class)
    .extracting("reason")
    .asString()
    .isEqualTo("The blog post does not exist. ID: " + Integer.MAX_VALUE);
}
`}</code></pre>
    <p>{`Run all the test cases on your IDE or using Gradle.
Check that you see the test is passed.`}</p>
    <h2 {...{
      "id": "5-test-retrieving-multiple-posts",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#5-test-retrieving-multiple-posts",
        "aria-label": "5 test retrieving multiple posts permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`5. Test retrieving multiple posts`}</h2>
    <p>{`Finally, let's test if we can retrieve multiple posts.
Add a test method like the following to create the second blog post and test retrieving the list of blog posts.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java",
        "metastring": "filename=BlogServiceTest.java",
        "filename": "BlogServiceTest.java"
      }}>{`import java.util.List;
...
@Test
@Order(4)
void listBlogPosts() throws TException {
  final BlogClient client = new BlogClient(server.httpUri(), "/thrift");
  client.createBlogPost("My second blog", "Armeria is awesome!");

  final List<BlogPost> blogs = client.listBlogPosts(false);
  assertThat(blogs).hasSize(2);
  final BlogPost firstBlog = blogs.get(0);
  assertThat(firstBlog.getTitle()).isEqualTo("My first blog");
  assertThat(firstBlog.getContent()).isEqualTo("Hello Armeria!");

  final BlogPost secondBlog = blogs.get(1);
  assertThat(secondBlog.getTitle()).isEqualTo("My second blog");
  assertThat(secondBlog.getContent()).isEqualTo("Armeria is awesome!");
}
`}</code></pre>
    <p>{`Run all the test cases on your IDE or using Gradle.
Check that you see the test is passed.`}</p>
    <h2 {...{
      "id": "whats-next",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#whats-next",
        "aria-label": "whats next permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What's next`}</h2>
    <p>{`In this step, we've implemented service methods and client methods to retrieve blog posts.`}</p>
    <p>{`Next, at `}<a parentName="p" {...{
        "href": "/tutorials/thrift/blog/implement-update"
      }}>{`Step 5. Implement UPDATE`}</a>{`, we'll implement an UPDATE operation to update a blog post.`}</p>
    <TutorialSteps current={4} mdxType="TutorialSteps" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      